/**
 * KKH BabyCare
 * This is a server where a user of the KKH online services can order a BabyCarePackage.
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface BabyCareBvs {
  /**
   * Salutation of the person
   */
  salutation: BabyCareBvs.SalutationBvs;
  /**
   * Givenname of the person
   */
  givenName: string;
  /**
   * Familyname of the person
   */
  familyName: string;
  /**
   * Street of the person
   */
  street: string;
  /**
   * House number of the person
   */
  houseNumber: string;
  /**
   * Zip of the city
   */
  zip: string;
  /**
   * City of the person
   */
  city: string;
  /**
   * Phone of the person
   */
  phone?: string;
  /**
   * Mail of the person
   */
  email: string;
  /**
   * Insurancenumber of the provider. Is required, if birthdate is null.
   */
  kvnr?: string;
  /**
   * birthdate of the person. Is required, if kvnr is null.
   */
  birthDate?: string;
  /**
   * Position number of the provider
   */
  deliveryDate: string;
  /**
   * Order type that the person has chosen
   */
  typeOfOrder: BabyCareBvs.TypeOfOrderBvs;
  /**
   * How did you find out about Babycare?
   */
  madeAwareThrough: BabyCareBvs.MadeAwareThroughBvs;
}
export namespace BabyCareBvs {
  export type SalutationBvs = 'diverse' | 'female' | 'male';
  export const SalutationBvs = {
    Diverse: 'diverse' as SalutationBvs,
    Female: 'female' as SalutationBvs,
    Male: 'male' as SalutationBvs,
  };
  export type TypeOfOrderBvs = 'unlockAppCode' | 'babycareGuide';
  export const TypeOfOrderBvs = {
    UnlockAppCode: 'unlockAppCode' as TypeOfOrderBvs,
    BabycareGuide: 'babycareGuide' as TypeOfOrderBvs,
  };
  export type MadeAwareThroughBvs = 'kkhde' | 'gynecologist' | 'kkhCustomerAdvice' | 'searchEngines' | 'otherWebsites';
  export const MadeAwareThroughBvs = {
    Kkhde: 'kkhde' as MadeAwareThroughBvs,
    Gynecologist: 'gynecologist' as MadeAwareThroughBvs,
    KkhCustomerAdvice: 'kkhCustomerAdvice' as MadeAwareThroughBvs,
    SearchEngines: 'searchEngines' as MadeAwareThroughBvs,
    OtherWebsites: 'otherWebsites' as MadeAwareThroughBvs,
  };
}
