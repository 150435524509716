/**
 * KKH BabyCare
 * This is a server where a user of the KKH online services can order a BabyCarePackage.
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface BabyCareBitgo {
  /**
   * Salutation of the person
   */
  salutation: BabyCareBitgo.SalutationBitgo;
  /**
   * Givenname of the person
   */
  givenName: string;
  /**
   * Familyname of the person
   */
  familyName: string;
  /**
   * Street of the person
   */
  street: string;
  /**
   * House number of the person
   */
  houseNumber: string;
  /**
   * Zip of the city
   */
  zip: string;
  /**
   * City of the person
   */
  city: string;
  /**
   * Phone of the person
   */
  phone?: string;
  /**
   * Mail of the person
   */
  email: string;
  /**
   * Insurancenumber of the provider. Is required, if birthdate is null.
   */
  kvnr?: string;
  /**
   * birthdate of the person. Is required, if kvnr is null.
   */
  birthDate?: string;
  /**
   * Position number of the provider
   */
  deliveryDate: string;
  /**
   * Order type that the person has chosen
   */
  typeOfOrder: BabyCareBitgo.TypeOfOrderBitgo;
  /**
   * How did you find out about Babycare?
   */
  madeAwareThrough: BabyCareBitgo.MadeAwareThroughBitgo;
}
export namespace BabyCareBitgo {
  export type SalutationBitgo = 'diverse' | 'female' | 'male';
  export const SalutationBitgo = {
    Diverse: 'diverse' as SalutationBitgo,
    Female: 'female' as SalutationBitgo,
    Male: 'male' as SalutationBitgo,
  };
  export type TypeOfOrderBitgo = 'unlockAppCode' | 'babycareGuide';
  export const TypeOfOrderBitgo = {
    UnlockAppCode: 'unlockAppCode' as TypeOfOrderBitgo,
    BabycareGuide: 'babycareGuide' as TypeOfOrderBitgo,
  };
  export type MadeAwareThroughBitgo =
    | 'kkhde'
    | 'gynecologist'
    | 'kkhCustomerAdvice'
    | 'searchEngines'
    | 'otherWebsites';
  export const MadeAwareThroughBitgo = {
    Kkhde: 'kkhde' as MadeAwareThroughBitgo,
    Gynecologist: 'gynecologist' as MadeAwareThroughBitgo,
    KkhCustomerAdvice: 'kkhCustomerAdvice' as MadeAwareThroughBitgo,
    SearchEngines: 'searchEngines' as MadeAwareThroughBitgo,
    OtherWebsites: 'otherWebsites' as MadeAwareThroughBitgo,
  };
}
